export const AssetCache = {
  Image: {
    Logo: require('./assets/images/logo.png'),
    Laptop:require('./assets/images/laptop.png'),
    SSO:require('./assets/images/sso.png'),
    OSLAVYADARKY:require('./assets/images/oslavyadarky.jpg'),
    SMERSEVER:require('./assets/images/smersever.jpg'),
    ENERGY_UPGRADE:require('./assets/images/energy_upgrade.jpg'),
    LUKAS:require('./assets/images/lukas.jpg'),
    DAN:require('./assets/images/dan.jpg'),
    Icons: {
      Map:require('./assets/images/icons/map-icon-white.png'),
      Phone:require('./assets/images/icons/phone-icon-white.png'),
      Envelop:require('./assets/images/icons/email-icon-white.png')
    },
    Test: {
      One:require('./assets/images/icons/responsive-icon-white.png'),
      Two:require('./assets/images/icons/design-icon-white.png'),
      Three:require('./assets/images/icons/idea-icon-white.png'),
      4:require('./assets/images/laptop.png'),

    }
  }
};
